// extracted by mini-css-extract-plugin
export var cardWrapper = "CustomerLogoSection-module--cardWrapper--884de";
export var image = "CustomerLogoSection-module--image--a2273";
export var opacityEnter = "CustomerLogoSection-module--opacityEnter--403ba";
export var opacityExit = "CustomerLogoSection-module--opacityExit--c0a0b";
export var rollDown = "CustomerLogoSection-module--rollDown--b6851";
export var rollUp = "CustomerLogoSection-module--rollUp--770d9";
export var slideInDown = "CustomerLogoSection-module--slideInDown--395aa";
export var slideOutUp = "CustomerLogoSection-module--slideOutUp--95a49";
export var splashEnter = "CustomerLogoSection-module--splashEnter--016d8";
export var splashExit = "CustomerLogoSection-module--splashExit--0fe51";
export var title = "CustomerLogoSection-module--title--03170";
export var wrapper = "CustomerLogoSection-module--wrapper--abb0f";