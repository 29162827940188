import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import withLayout from '@/base/containers/withLayout';
import SectionRichText from '@/components/sections/SectionRichText';
import Image from '@/components/elements/Image';
import CustomerLogoSection from '@/components/sections/CustomerLogoSection';
import useMarketoForm from '@/base/hooks/useMarketoForm';
import Loader from '@/components/modules/Loader';
import { ThankYouMessage } from '@/components/modules/ThankYouMessage/ThankYouMessage';
import { BREAKPOINTS } from '@/base/consts/breakpoints';
import type { GridItemTypes } from '@/components/modules/Grid/Grid.types';
import type { DemoPageTypes } from './DemoPage.types';
import * as styles from './DemoPage.module.scss';

export function DemoPage({ content }: StoryblokPage<DemoPageTypes>) {
  const isTablet = useMediaQuery({ query: BREAKPOINTS.LG });
  const formWrapperRef = useRef<HTMLDivElement>(null);

  const { demo_title, eyebrow, logos, logo_header, marketo_formId, button_label, thank_you_message } = content;

  const showLogosSection = !!logo_header?.content?.[0].content && !!logos?.length;

  const { formLoaded, submitted, checkboxVisible } = useMarketoForm({
    formId: marketo_formId,
    submitLabelOverride: button_label,
  });

  useEffect(() => {
    if (formWrapperRef && formWrapperRef.current) {
      if (submitted && isTablet) {
        formWrapperRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [submitted]);

  const richTextComponents = {
    component_grid_item: ({ _uid, icon, body }: GridItemTypes) => (
      <div key={_uid} className={styles.card}>
        {icon && (
          <div className={styles.imageWrapper}>
            <Image data={icon} fluid className={styles.img} />
          </div>
        )}
        <div className={styles.textArea}>
          <SectionRichText body={body} className={styles} />
        </div>
      </div>
    ),
  };

  return (
    <section>
      <div className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          {eyebrow && <p className={styles.eyebrow}>{eyebrow}</p>}
          <SectionRichText body={demo_title} className={styles} components={richTextComponents} />
        </div>
        <aside className={styles.asideWrapper}>
          <div className={styles.aside}>
            <div id="marketoForm" ref={formWrapperRef} className={styles.formWrapper}>
              <form
                id={`mktoForm_${marketo_formId}`}
                className="mktoContact"
                data-checkbox-visibility={String(checkboxVisible)}
                data-visible={String(!submitted)}
              />
              <div className={styles.thanksMessageWrapper}>
                <ThankYouMessage body={thank_you_message} visibility={submitted} />
              </div>
              <Loader visible={!formLoaded} />
            </div>
          </div>
        </aside>
      </div>

      {showLogosSection && (
        <div className={`${styles.wrapper} container`}>
          <div className="row">
            <div className="col-lg-12">
              {logo_header?.type && logo_header?.content?.[0].content && (
                <div className={styles.logoHeading}>
                  <SectionRichText body={logo_header} className={styles} />
                </div>
              )}
              <CustomerLogoSection logos={logos} />
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default function DemoPageTemplate({ pageContext, location }: StoryblokTemplate<DemoPageTypes>) {
  return withLayout(DemoPage, { ...pageContext, location });
}
