import React from 'react';
import Image from '@/components/elements/Image';
import ModuleWrapper from '@/components/modules/ModuleWrapper/ModuleWrapper';
import type { CustomerLogoSectionTypes } from './CustomerLogoSection.types';
import * as styles from './CustomerLogoSection.module.scss';

export default function CustomerLogoSection({
  title,
  logos,
  top_spacing,
  bottom_spacing,
  items_in_row = 4,
}: CustomerLogoSectionTypes) {
  if (logos.length === 0) return null;
  return (
    <ModuleWrapper topSpacing={top_spacing} bottomSpacing={bottom_spacing}>
      {title && <h2 className={styles.title}>{title}</h2>}
      <ul className={styles.wrapper}>
        {logos?.map(({ src }) => (
          <li className={styles.cardWrapper} key={src?._uid} data-items-in-row={String(items_in_row)}>
            <Image data={src} fluid className={styles.image} />
          </li>
        ))}
      </ul>
    </ModuleWrapper>
  );
}
