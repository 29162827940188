// extracted by mini-css-extract-plugin
export var aside = "DemoPage-module--aside--9c70d";
export var asideWrapper = "DemoPage-module--asideWrapper--ac83b";
export var bold = "DemoPage-module--bold--a2c02";
export var card = "DemoPage-module--card--12959";
export var contentWrapper = "DemoPage-module--contentWrapper--b22cd";
export var eyebrow = "DemoPage-module--eyebrow--68cb5";
export var formWrapper = "DemoPage-module--formWrapper--bc025";
export var headingH1 = "DemoPage-module--headingH1--4f114";
export var headingH2 = "DemoPage-module--headingH2--19692";
export var imageWrapper = "DemoPage-module--imageWrapper--d8e2a";
export var img = "DemoPage-module--img--69d73";
export var list = "DemoPage-module--list--92d72";
export var listItem = "DemoPage-module--listItem--7288a";
export var loader = "DemoPage-module--loader--ddb53";
export var logoHeading = "DemoPage-module--logoHeading--88f22";
export var opacityEnter = "DemoPage-module--opacityEnter--976b5";
export var opacityExit = "DemoPage-module--opacityExit--377f4";
export var paragraph = "DemoPage-module--paragraph--23baa";
export var rollDown = "DemoPage-module--rollDown--270c9";
export var rollUp = "DemoPage-module--rollUp--026db";
export var slideInDown = "DemoPage-module--slideInDown--997f6";
export var slideOutUp = "DemoPage-module--slideOutUp--2e995";
export var splashEnter = "DemoPage-module--splashEnter--67e0c";
export var splashExit = "DemoPage-module--splashExit--0f2f9";
export var textArea = "DemoPage-module--textArea--1dd31";
export var thankYouWrapper = "DemoPage-module--thankYouWrapper--d7a74";
export var thanksMessageWrapper = "DemoPage-module--thanksMessageWrapper--ae1d0";
export var wrapper = "DemoPage-module--wrapper--b9338";